@import '/src/assets/scss/theme.scss';
@import '/src/assets/scss/styles.scss';
@import '/src/assets/icons/icomoon.scss';
@import '/src/assets/scss/swiper.scss';

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }
}
