@import 'swiper/swiper-bundle.css';

.swiper-container {
  &.swiper-container-free-mode {
    .swiper-wrapper {
      .swiper-slide {
        width: auto;
      }
    }
  }
}
