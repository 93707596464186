
h1 {
  color: #000;
}

input {
  outline: none;
  padding: 10px;
}

.text-right {
  margin-left: auto;
  text-align: right;
}

.content-middle {
  align-items: center;
  align-self: center;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1250px;
  }
}
