::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  opacity: 0.8;
  background: #c4c4c4;
  border-radius: 85px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.navbar {
  .navbar-flex {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.breadcrumb {
  background-color: #fff;
  padding-left: 0;

  .breadcrumb-item {
    font-size: 14px;

    + ::before {
      content: '\25CF';
      font-size: 10px;
      line-height: 20px;
    }

    a {
      color: #2e2f37;
      text-decoration: none;
    }
  }
}

.accordion {
  .card {
    .card-header {
      cursor: pointer;

      i {
        transform: rotate(-90deg);
        transition: 0.5s;
      }

      &.show {
        i {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.gmapStyle {
  .gmnoprint {
    .gmnoprint {
      div {
        border-radius: 30px !important;

        button img {
          height: 13px !important;
          width: 13px !important;
        }
      }
    }
  }

  .gm-fullscreen-control {
    border-radius: 6px !important;
    height: 30px !important;
    width: 30px !important;

    img {
      height: 13px !important;
      width: 13px !important;
    }
  }

  .gm-style-cc {
    display: none;
  }

  .gm-style {
    div {
      a {
        div {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .navbar {
    padding: 1rem 1rem;
  }
}
